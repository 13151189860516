export const observer = {
    addSubscriber(event, cb) {
        let subs = this.subscribers[event] || (this.subscribers[event] = [])
        subs[subs.length] = cb
        this.subscribers[event] = subs.filter(sub => typeof sub === 'function')
    },
    removeSubscriber(event, cb) {
        let subs = this.subscribers[event] || []
        // console.log('removeSubscriber', this.subscribers)
        subs.splice(subs.indexOf(cb), 1)
    },
    publish(event) {
        let subs = this.subscribers[event]
        for (var i = 0; i < subs.length; i++) {
            if (typeof subs[i] === 'function') {
                subs[i].apply(null, arguments)
            }
        }
    },
    clearPublish(event) {
        let subs = this.subscribers[event]
        for (var i = 0; i < subs.length; i++) {
            if (typeof subs[i] === 'function') {
                subs[i].apply(null, arguments)
                subs[i] = null
            }
        }
    },
    make(o) {
        for (var i in this) {
            o[i] = this[i]
            o.subscribers = {}
        }
    }
}