/* eslint-disable no-useless-return */
import { observer } from '../observer.js'
import request from '@/apis/index'
export function getGeoJson(geoName = 'china') {
  return request({ url: '/pm/images/geoJson/' + geoName + '.json' })
}
const logo = require('@/assets/logo_min.png')
const graphic = {
  type: 'image',
  $action: 'replace',
  id: 'logo',
  right: 10,
  bottom: 10,
  z: -1,
  bounding: 'raw',
  origin: [75, 75],
  style: {
    image: logo,
    width: 140,
    height: 54,
    opacity: 0.2
  }
}
const STORE = {
  line: {
    xAxis: {
      // boundaryGap: false,
      axisTick: {
        alignWithLabel: true
      }
    }
  },
  bar: {
    xAxis: {
      boundaryGap: true,
      axisTick: {
        alignWithLabel: true
      }
    }
  },
  map: {
    backgroundColor: '#fff',
    title: {
      text: '',
      link: '',
      x: 'center',
      padding: 10,
      textStyle: {
        fontSize: 16,
        fontWeight: 'normal'
      }
    },
    tooltip: {
      trigger: 'item'
    },
    visualMap: {
      min: 0,
      max: 2500,
      left: 'left',
      top: 'bottom',
      // text: ['高', '低'],// 文本，默认为数值文本
      calculable: true,
      color: ['#04bf02', '#f2f2f2']
    }
  },
  'bar_line.toolbox': {
    toolbox: {
      orient: 'vertical',
      showTitle: false,
      itemSize: 20,
      itemGap: 15,
      feature: {
        dataZoom: {
          yAxisIndex: false
        },
        magicType: { show: true, type: ['line', 'bar'] },
        dataView: {
          readOnly: true,
          optionToContent: function(opt) {
            var table = `<div style='overflow: auto;height: inherit;'>`
            for (var index = 0, len = opt.xAxis.length; index < len; index++) {
              var l
              var axisData = opt.xAxis[index].data
              var series = opt.series
              table +=
                `<table class='chart-DataView-Table' style='width:100%;text-align:center'><tbody><tr style='background:green;color:#fff'>` +
                '<td>' +
                opt.xAxis[index].name +
                '</td>'
              l = series.length
              for (var si = 0; si < l; si++) {
                table += '<td>' + series[si].name + '</td>'
              }
              table += '</tr>'
              l = axisData.length
              for (var i = 0; i < l; i++) {
                table += '<tr>' + '<td>' + axisData[i] + '</td>'
                for (var ssi = 0, ll = series.length; ssi < ll; ssi++) {
                  var value = series[ssi].data[i]
                  if (value instanceof Object) {
                    value = value.value
                  }
                  table += '<td>' + value + '</td>'
                }
                table += '</tr>'
              }
              table += '</tbody></table>'
            }
            table += '</div>'
            return table
          }
        }
      }
    }
  },
  'pie.toolbox': {
    toolbox: {
      itemSize: 20,
      itemGap: 15,
      feature: {
        dataView: {
          readOnly: true
          // optionToContent(opt) {
          //     var table = `<div style='overflow: auto; height: inherit; '>`
          //     var series = opt.series[0];
          //     if (opt.title[0].text) {
          //         table += `<h3 style='text- align: center; padding: 5px; '>' + opt.title[0].text + '<h3>`
          //     }
          //     table += `<table class='chart- DataView - Table - pie' style='width: 100 %;text - align:center'>`;
          //     table += `<thead><tr style='background: green; color: #fff'><td>' + series.name + '</td>' + '<td>' + '值' + '</td>' + '<td>' + '百分比' + '</td></tr></thead>`;
          //     table += '<tbody>';
          //     for (var index = 0, len = series.data.length; index < len; index++) {
          //         table += `<tr style='background: ' + color + '; color: #fff; font - size: 14px; line - height:26px; font - weight: 400; '><td>' + series.data[index].name + '</td>' + '<td>' + series.data[index].value + '</td>' + '<td>' + (series.data[index].value / sum * 100).toFixed(2) + '%' + '</td></tr>`;
          //     }
          //     table += '</tbody></table>';
          //     table += '</div>';
          //     return table;
          // }
        },
        saveAsImage: {}
      }
    }
  },
  line_bar_baseOptin: {
    // 设置绿色为首项颜色
    color: [
      '#04bf02',
      '#ff3a35',
      '#2f4554',
      '#61a0a8',
      '#d48265',
      '#91c7ae',
      '#749f83',
      '#ca8622',
      '#bda29a',
      '#6e7074',
      '#546570',
      '#c4ccd3'
    ],
    // 白色背景
    backgroundColor: '#fff',
    title: {
      text: '',
      link: '',
      x: 'center',
      padding: 10,
      textStyle: {
        fontSize: 16,
        fontWeight: 'normal'
      }
      // show: false,
    },
    tooltip: {
      trigger: 'axis'
    },
    legend: { x: 0, data: [] },
    grid: {
      x: '0',
      x2: '40', // 40
      bottom: '40',
      containLabel: true
    },
    xAxis: {
      type: 'category',
      name: '',
      data: []
    },
    yAxis: {
      type: 'value',
      name: ''
    },
    series: [],
    dataZoom: [
      {
        // 这个dataZoom组件，默认控制x轴。
        type: 'slider', // 这个 dataZoom 组件是 slider 型 dataZoom 组件
        start: 0, // 左边在 10% 的位置。
        end: 100 // 右边在 60% 的位置。
      }
      // {   // 这个dataZoom组件，也控制x轴。
      //     type: 'inside', // 这个 dataZoom 组件是 inside 型 dataZoom 组件
      //     start: 0,      // 左边在 10% 的位置。
      //     end: 100         // 右边在 60% 的位置。
      // }
    ]
  },
  pie_baseOption: {
    // 设置绿色为首项颜色
    color: [
      '#04bf02',
      '#ff3a35',
      '#2f4554',
      '#61a0a8',
      '#d48265',
      '#91c7ae',
      '#749f83',
      '#ca8622',
      '#bda29a',
      '#6e7074',
      '#546570',
      '#c4ccd3'
    ],
    backgroundColor: '#fff',
    title: {
      text: '',
      link: '',
      x: 'center',
      padding: 10,
      textStyle: {
        fontSize: 16,
        fontWeight: 'normal'
      }
      // show: false,
    },
    tooltip: {
      trigger: 'item',
      formatter: '{a} <br/>{b}: {c} ({d}%)'
    },
    legend: {
      orient: 'vertical',
      x: 'left',
      data: []
    },
    series: [
      {
        name: '',
        type: 'pie',
        radius: ['50%', '75%'],
        avoidLabelOverlap: false,
        label: {
          normal: {
            show: false,
            position: 'center'
          },
          emphasis: {
            show: true,
            textStyle: {
              fontSize: '30',
              fontWeight: 'bold'
            }
          }
        },
        labelLine: {
          normal: {
            show: false
          }
        },
        data: []
      }
    ]
  },
  'default.dataZoom': {
    grid: {
      bottom: '40'
    },
    dataZoom: [
      {
        // 这个dataZoom组件，默认控制x轴。
        type: 'slider', // 这个 dataZoom 组件是 slider 型 dataZoom 组件
        start: 0, // 左边在 10% 的位置。
        end: 100 // 右边在 60% 的位置。
      }
      // {   // 这个dataZoom组件，也控制x轴。
      //     type: 'inside', // 这个 dataZoom 组件是 inside 型 dataZoom 组件
      //     start: 0,      // 左边在 10% 的位置。
      //     end: 100         // 右边在 60% 的位置。
      // }
    ]
  },
  graphic: {
    graphic: [graphic]
  }
}
const FORMULA = {
  line: ['line_bar_baseOptin', 'bar_line.toolbox', 'line', 'graphic'],
  bar: ['line_bar_baseOptin', 'bar_line.toolbox', 'bar', 'graphic'],
  pie: ['pie_baseOption', 'pie.toolbox', 'graphic'],
  map: 'map'
}
export function clone(source, target) {
  let keys = Object.keys(target)
  keys.forEach(key => {
    if (key in source) {
      if (Object.prototype.toString.call(target[key]) === '[object Object]') {
        clone(source[key], target[key])
      } else if (Array.isArray(target[key])) {
        source[key] = Array.from(target[key])
      } else {
        source[key] = target[key]
      }
    } else if (
      Object.prototype.toString.call(target[key]) === '[object Object]'
    ) {
      source[key] = {}
      clone(source[key], target[key])
    } else if (Array.isArray(target[key])) {
      source[key] = Array.from(target[key])
    } else {
      source[key] = target[key]
    }
  })
  return source
}
export function getConfig(type) {
  var formula = FORMULA[type]
  if (!formula) return
  if (Array.isArray(formula)) {
    return formula.reduce((pre, now) => {
      clone(pre, JSON.parse(JSON.stringify(STORE[now])))
      return pre
    }, {})
  } else {
    return Object.assign({}, STORE[formula])
  }
}
let geoJsonServer = {
  gettingStore: {},
  geoJsonStore: {},
  get(geoName) {
    if (this.gettingStore[geoName]) {
      if (this.geoJsonStore[geoName]) {
        this.pulish(geoName, this.geoJsonStore[geoName])
      }
      return
    }
    this.gettingStore[geoName] = true
    var vm = this
    getGeoJson(geoName).then(geoJson => {
      vm.geoJsonStore[geoName] = geoJson
      vm.clearPublish(geoName, geoJson)
    })
  }
}
observer.make(geoJsonServer)
export function getMap(geoName, echarts) {
  return new Promise(resolve => {
    let result = echarts.getMap(geoName)
    if (!!result && !!result.geoJson) {
      resolve()
    } else {
      var sub = {
        get(geoName, geoJson) {
          echarts.registerMap(geoName, geoJson)
          resolve()
        }
      }
      geoJsonServer.addSubscriber(geoName, sub.get)
      geoJsonServer.get(geoName)
    }
  })
}

export async function chartFactry(config, echarts) {
  const { type } = config
  if (!type) {
    return config
  }
  let baseCfg = getConfig(config.type)
  if (baseCfg) {
    switch (type) {
      case 'line':
      case 'bar':
        baseCfg = getLineOrBarCfg(config, baseCfg)
        break
      case 'pie':
        baseCfg = getPieCfg(config, baseCfg)
        break
      case 'map':
        baseCfg = await getMapCfg(config, baseCfg, echarts)
        break
      default:
        break
    }
  }
  return baseCfg || config
}

async function getLineOrBarCfg(config, baseCfg) {
  if (!config['xAxis.data'] || !Array.isArray(config['xAxis.data'])) {
    window.console.error('echarts ', '配置对象必须有 xAxis.data 属性')
    return baseCfg
  }
  if (!config['series']) {
    window.console.error('echarts:line/bar ', '配置对象必须有 series 数组属性')
    return baseCfg
  }
  // 组装option
  for (let key in config) {
    if (key === 'type') continue
    if (key === 'xAxis.data') {
      config[key].forEach(function(item) {
        baseCfg.xAxis.data.push(item)
      })
      continue
    }
    if (key === 'series') {
      if (Array.isArray(config[key])) {
        config[key].forEach(function(item) {
          if (config[key].length > 1) {
            baseCfg.legend.data.push(item.name)
          }
          if (!item.type) {
            item.type = config.type
          }
          item.smooth = true
          // item.smooth = item.smooth === undefined ? true : item.smooth;
          // item.step = item.step === undefined ? 'middle' : item.step;
          baseCfg.series.push(item)
        })
      } else {
        if (!config[key].type) {
          config[key].type = config.type
        }
        baseCfg[key].push(Object.assign(baseCfg[key][0], config[key]))
      }
      continue
    }
    if (config[key] instanceof Object) {
      baseCfg[key] = Object.assign(baseCfg[key], config[key]) //  $.extend(true, );
    } else {
      if (
        config[key] === null ||
        config[key] === 'null' ||
        config[key] === ''
      ) {
        continue
      } else {
        baseCfg[key] = config[key]
      }
    }
  }
  // 修正option
  // chartConfigStore.assembleConfig(baseCfg,'default.dataZoom');
  return baseCfg
}
function getPieCfg(config, result) {
  if (!config['series']) {
    window.console.error('echarts:pie ', '配置对象必须有 series 属性')
    return
  }
  for (let key in config) {
    if (key === 'type') continue
    if (key === 'series') {
      if (Array.isArray(config[key])) {
        config[key] = config[key][0]
      }
      result.series[0].name = config[key].name ? config[key].name : ''
      if (!Array.isArray(config[key].data)) {
        window.console.error(
          'echarts:pie ',
          '配置对象必须有 series.data 数组对象属性'
        )
        return
      }
      config[key].data.forEach(function(item) {
        result.legend.data.push(item.name)
        result.series[0].data.push(item)
      })
      continue
    }
    if (!(config[key] instanceof Object)) {
      if (
        config[key] === null ||
        config[key] === 'null' ||
        config[key] === ''
      ) {
        continue
      } else {
        result[key] = config[key]
      }
    } else {
      result[key] = Object.assign(result[key], config[key]) // $.extend(true, );
    }
  }
  return result
}
async function getMapCfg(config, result, echarts) {
  if (config.geo === undefined) {
    config.geo = { map: 'china' }
  } else if (config.geo.map === false) {
    config.geo.map = 'china'
  }
  let hasMap = echarts.getMap(config.geo.map)
  if (!hasMap) {
    try {
      const geoJson = await getGeoJson(config.geo.map)
      echarts.registerMap(config.geo.map, geoJson)
    } catch (error) {
      window.console.log(error)
    }
  }
  config.series = config.series.map(series => {
    return Object.assign(
      {},
      {
        type: 'map',
        mapType: config.geo.map,
        roam: false,
        label: {
          normal: {
            show: false
          },
          emphasis: {
            show: true
          }
        }
      },
      series
    )
  })
  return Object.assign({}, result, config)
}
