/* eslint-disable */
<template>
  <div
    class="xa-wrap chart-wrap"
    :style="{
      height: fullHeight ? '100%' : height + 'px',
      width: width + 'px'
    }"
  >
    <spinnerLine v-if="isLoading"></spinnerLine>
    <div class="chart" ref="chart"></div>
  </div>
</template>
<script>
/* eslint-disable */
import echarts from 'echarts'
import 'echarts/map/js/china.js'
let geoMapPingyin = require('@/assets/echartsGeoJson/geoMapPingyin.json')
import { chartFactry, getMap } from '@/controllers/echarts/factoryController.js'
export default {
  name: 'echarts',
  echartsInstance: {},
  data() {
    return {
      resizeEventHandler: {},
      resize: {},
      name: 'testController',
      mOptions: null,
      isLoading: false
    }
  },
  props: {
    servicer: {},
    changeView: {
      type: Number
    },
    options: {
      type: Object,
      require: true
    },
    // 是否watch options
    keepWatch: {
      type: Boolean,
      default: false
    },
    display: {
      type: Boolean,
      default: true
    },
    direct: {
      type: Boolean,
      default: false
    },
    width: {
      type: Number
    },
    height: {
      type: Number
    },
    fullHeight: {
      type: Boolean,
      default: false
    },
    // 地图类型 如果是map 则要做些其它处理
    echartsType: {
      type: String,
      default: ''
    },
    // 事件处理
    eventControllter: null
  },
  watch: {
    options: {
      handler(val, oldVal) {
        if (this.keepWatch === true) {
          this.mOptions = val
          this.init()
        }
      },
      deep: true
    },
    navClassState() {
      this.$nextTick &&
        this.$nextTick(function() {
          setTimeout(() => {
            this.$refs.chart && this.$refs.chart.instance.resize()
          }, 500)
        })
    },
    changeView() {
      this.$nextTick(function() {
        this.$refs.chart.instance.resize()
      })
    },
    display(val, oldVal) {
      if (val === true && oldVal === false) {
        this.$nextTick(function() {
          this.$refs.chart.instance.resize()
        })
      }
    }
  },
  computed: {
    navClassState() {
      if (this.$store && this.$store.state.Navigation) {
        return this.$store.state.Navigation.isNavCollapsed
      } else {
        return undefined
      }
    }
  },
  methods: {
    init() {
      let mOp = this.mOptions || this.options
      if (Object.prototype.toString.call(mOp) !== '[object Object]')
        mOp = JSON.parse(mOp)
      if (!Object.keys(mOp).length) return
      if (this.direct || !mOp.type) {
        this.setEchartsOption(mOp, this.echartsType === 'map' ? 'china' : '')
        return
      }
      mOp = JSON.parse(JSON.stringify(mOp))
      chartFactry(mOp, echarts).then(config => this.setEchartsOption(config))
    },
    setEchartsOption(mOp, geoName) {
      if ((mOp.geo && mOp.geo.map) || geoName) {
        geoName = geoName || mOp.geo.map
        getMap(geoName, echarts).then(() => {
          this.$refs.chart.instance.setOption(mOp)
          this.$refs.chart.instance.hideLoading()
        })
      } else {
        if (this.$refs.chart !== undefined) {
          this.$refs.chart.instance.setOption(mOp)
          this.$refs.chart.instance.hideLoading()
        }
      }
    },
    resizeFn(e) {
      clearTimeout(this.$options._$debounceTimeout)
      this.$options._$debounceTimeout = setTimeout(() => {
        this.$refs.chart.instance.resize()
      }, 125)
    },
    addMapEchartsEvent() {
      this.$refs.chart.instance.on('click', avg => {
        if (
          this.eventControllter &&
          typeof this.eventControllter === 'function'
        ) {
          this.eventControllter(avg, this)
          return
        }
        let geoName = geoMapPingyin[avg.name]
        if (geoName) {
          getMap(geoName, echarts).then(() => {
            this.$refs.chart.instance.clear()
            this.setEchartsOption({
              title: {
                text: '全国 > ' + avg.name,
                x: 'center'
              },
              geo: {
                map: geoName,
                roam: true,
                label: {
                  normal: {
                    show: true
                  },
                  emphasis: {
                    show: true
                  }
                }
              }
            })
            setTimeout(() => {
              this.resizeFn()
            }, 1000)
          })
        } else {
          this.$refs.chart.instance.clear()
          this.init()
        }
      })
    },
    getEchartsInstance() {
      return this.$refs.chart.instance
    }
  },
  mounted() {
    this.$refs.chart.instance = echarts.init(this.$refs.chart)
    this.$refs.chart.instance.showLoading({ text: '绘图中...' })
    this.$refs.chart.addEventListener('resize', this.resizeFn, false)
    window.addEventListener('resize', this.resizeFn)
    this.init()
    if (this.options.type === 'map' || this.echartsType === 'map') {
      this.addMapEchartsEvent()
    }
    if (this.servicer !== undefined) {
      this.servicer.init(this.setEchartsOption.bind(this), this)
      this.servicer.worker(this.setEchartsOption.bind(this))
    }
  },
  beforeDestroy() {
    let vm = this
    this.$refs.chart.instance.dispose()
    this.$refs.chart.removeEventListener('resize', vm.resizeFn, false)
    window.removeEventListener('resize', vm.resizeFn)
    if (this.servicer && this.servicer.stop) {
      console.info('[echarts]:servicer beforeDestroy')
      this.servicer.stop()
    }
  }
}
</script>
<style scoped>
.chart {
  height: 100%;
  /* padding: 0 5px; */
  box-sizing: border-box;
  background: #fff !important;
}
.chart-wrap {
  height: 420px;
}
</style>
